<template>
  <div class="about">
    <div class="pageTtlBlock">
      <h1 class="pageTtlBlock__ttl">
        ABOUT
        <span>GENBA SHIKOとは？</span>
      </h1>
    </div>
    <div class="inner">
      <section class="about__outline js-scroll">
        <h3 class="about__outline__ttl">
          現場を見て初めて、
          <br />本当に必要なソリューションがわかる
        </h3>
        <p class="about__outline__txt">
          検査照明は、さまざまな検査工程の生産性を左右する重要なファクターです。光源の形状の影響でキズや色ムラが判別しにくい。眩しさで作業者の眼が疲れやすい。そうした照明に関する課題を解決することで、生産性や作業効率の大幅な改善も可能になります。検査照明における課題は現場に行かなければわかりません。Luciの【GENBA SHIKO】は自動車製造ラインの検査の現場に特化して、オリジナルの照明装置の開発製造や、自動化する工程の照明による改善を実施します。検査照明のプロフェッショナルの視点から現場で課題を発見し、コンサルティングを通じて、本当に必要なソリューションを提供しています。
        </p>
      </section>
      <div class="about__contents">
        <div class="about__contents__block js-scroll">
          <div class="about__contents__block__txtBlock">
            <p class="about__contents__block__ttl">
              現場主義
            </p>
            <p class="about__contents__block__txt">
              【GENBA
              SHIKO】は現場を何よりも重視します。検査ラインを訪問して、自らの眼で検査環境、作業内容、ワークの形状や特性、照明器具と影の位置関係、作業者の動きなどを詳細に観察。各種データを測定し、現場の方々からヒアリングを重ねて、問題点や課題を把握していきます。そして、光の原理まで掘り下げて解決策を考えて提案を行っています。大きな生産性向上につながるソリューションは、多くのお客様から高い評価をいただいています。
            </p>
          </div>
          <p class="about__contents__block__img" />
        </div>
        <div class="about__contents__block js-scroll">
          <div class="about__contents__block__txtBlock">
            <p class="about__contents__block__ttl">
              用途提案
            </p>
            <p class="about__contents__block__txt">
              ひと口に検査照明といっても、工程によって照明に求められる機能や性能が大きく異なります。たとえば、エンジンやパワートレインなどの、形状が複雑な鋳造・鍛造品の検査工程の場合には、影の影響で不良の見落としが発生しやすくなるため、その影響を最小限に抑えられるような解決策が求められます。【GENBA
              SHIKO】では豊富な知見や経験にもとづいて、最適な解決方法を検討し、現場の用途に合った機能と性能をもつプロダクトをご提案します。
            </p>
          </div>
          <p class="about__contents__block__img" />
        </div>
        <div class="about__contents__block js-scroll">
          <div class="about__contents__block__txtBlock">
            <p class="about__contents__block__ttl">
              用途開発
            </p>
            <p class="about__contents__block__txt">
              検査工程に合わせて照明器具をどのように使うかによっても、課題の改善度合いが異なってきます。【GENBA
              SHIKO】では使う用途に合わせた製品提案を行っています。定番製品であっても、現場に合わせてサイズ、形状、取り付け方法などを変える。設置場所によって必要なアタッチメントを考案する。また、光の当て方や強度を変えるなど、専門的な視点からアドバイスを行います。課題解決のために新たな製品や技術を開発することもあります。
            </p>
          </div>
          <p class="about__contents__block__img" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import scrollAnimMixin from "../../mixins/scrollAnimMixin.js";

export default {
  mixins: [scrollAnimMixin],
  head: {
  title: {
    inner: 'GENBA SHIKOとは'
  },
  meta: [
    { property: 'og:title', content: 'GENBA SHIKOとは｜GENBA SHIKO' },
    { name: 'description', content: '自動車工場における製造ラインの検査現場に特化した、オリジナルの照明装置の開発製造や、検査の自動化を、照明によって改善いたします。' },
    { property: 'og:description', content: '自動車工場における製造ラインの検査現場に特化した、オリジナルの照明装置の開発製造や、検査の自動化を、照明によって改善いたします。' },
    { property: 'og:url', content: 'https://luci.co.jp/genbashiko/about' },
    { property: 'og:type', content: 'article' }
    ]
  }
};
</script>
<style lang="scss" scoped>
.about {
  .pageTtlBlock {
    &::after {
      background: url("/genbashiko/img/about/bg.jpg") center/cover no-repeat;
    }
  }
  &__outline {
    margin-top: 60px;
    &__ttl {
      text-align: left;
      font-size: 3rem;
      letter-spacing: 0.2em;
    }
    &__txt {
      margin-top: 60px;
      line-height: 2.1;
      letter-spacing: 0.05em;
    }
  }
  &__contents {
    margin-top: 100px;
    &__block {
      display: flex;
      height: 346px;
      &:nth-of-type(2n) {
        flex-direction: row-reverse;
      }
      &__txtBlock {
        flex: 0 0 43%;
        max-width: 43%;
        background: #f2f0ec;
        padding: 34px 25px 38px 25px;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      &__img {
        position: relative;
        flex: 0 0 615px;
        max-width: 615px;
        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: url("/genbashiko/img/about/image01.jpg") center/cover no-repeat;
        }
      }
      &__ttl {
        font-size: 2.2rem;
        letter-spacing: 0.2em;
      }
      &__txt {
        line-height: 2.1;
        margin-top: 10px;
        letter-spacing: 0.05em;
      }
      + .about__contents__block {
        margin-top: 50px;
      }
      &:nth-of-type(2) {
        .about__contents__block__img::after {
          background: url("/genbashiko/img/about/image02.jpg") center/cover no-repeat;
        }
      }
      &:nth-of-type(3) {
        .about__contents__block__img::after {
          background: url("/genbashiko/img/about/image03.jpg") center/cover no-repeat;
        }
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .about {
    .pageTtlBlock {
      &::after {
        background: url("/genbashiko/img/about/bg-sp.jpg") center/cover no-repeat;
      }
    }
    &__outline {
      margin-top: 40px;
      &__ttl {
        font-size: 1.5rem;
      }
      &__txt {
        margin-top: 25px;
        font-size: 1.2rem;
      }
    }
    &__contents {
      margin-top: 50px;
      &__block {
        flex-direction: column-reverse;
        height: auto;
        &:nth-of-type(2n) {
          flex-direction: column-reverse;
        }
        &__img {
          flex: 0 0 100%;
          height: 0;
          padding-bottom: 56.25%;
          max-width: 100%;
        }
        &__txtBlock {
          padding: 25px 20px 30px;
          max-width: 100%;
        }
        &__ttl {
          font-size: 1.3rem;
        }
        &__txt {
          font-size: 1.1rem;
        }
        + .about__contents__block {
          margin-top: 40px;
        }
      }
    }
  }
}
</style>
